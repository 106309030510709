<template>
  <section class="dashboard-header">
    <div class="container-fluid">
      <div class="card-header">
        <h2>{{msg}}</h2>
        <div></div>
      </div>

      <div id="pago" class="row bg-white has-shadow my-3 pb-3">
        <div class="col-md-10">
          <div class="form mt-4 ml-2">
            <b-form-group label-cols-sm="4" label-cols-lg="3" label="Codigo: ">
              <b-form-select v-model="model.codigo" :options="giros" class="w-50" required></b-form-select>
            </b-form-group>

            <b-form-group label-cols-sm="4" label-cols-lg="3" label="Nombre comercial: ">
              <b-form-input class="w-60" v-model="model.nombrecomercial"></b-form-input>
            </b-form-group>

            <b-form-group label-cols-sm="4" label-cols-lg="3" label="Contacto: ">
              <b-form-input class="w-50" v-model="model.contacto"></b-form-input>
            </b-form-group>

            <b-form-group label-cols-sm="4" label-cols-lg="3" label="Telefono: ">
              <b-form-input class="w-40" v-model="model.telefono"></b-form-input>
            </b-form-group>

            <b-form-group label-cols-sm="4" label-cols-lg="3" label="Correo electrónico: ">
              <b-form-input class="w-50" v-model="model.email"></b-form-input>
            </b-form-group>

            <b-form-group label-cols-sm="4" label-cols-lg="3" label="Ciudad: ">
              <autocomplete
                ref="autocomplete"
                placeholder="Buscar ciudad"
                :request-headers="authHeaders"
                :source="source"
                :results-display="formattedDisplay"
                :initialDisplay="initialDisplay"
                :initialValue="initialValue"
                input-class="xform-controlx"
                @selected="setDatosCiudad"
              ></autocomplete>
              <span class="mt-2 font-weight-medio">{{ciudad}}</span>
            </b-form-group>

            <b-form-group label-cols-sm="4" label-cols-lg="3" label="Banco: ">
              <b-form-input class="w-40" v-model="model.banco"></b-form-input>
            </b-form-group>

            <b-form-group label-cols-sm="4" label-cols-lg="3" label="Titular: ">
              <b-form-input class="w-50" v-model="model.titular"></b-form-input>
            </b-form-group>

            <b-form-group label-cols-sm="4" label-cols-lg="3" label="Cuenta: ">
              <b-form-input class="w-40" v-model="model.cuenta"></b-form-input>
            </b-form-group>

            <b-form-group label-cols-sm="4" label-cols-lg="3" label="Tarjeta: ">
              <b-form-input class="w-40" v-model="model.tarjeta"></b-form-input>
            </b-form-group>

            <b-form-group label-cols-sm="4" label-cols-lg="3" label="CLABE: ">
              <b-form-input class="w-40" v-model="model.clabe"></b-form-input>
            </b-form-group>            
            
            <b-btn class="float-right mr-3 ml-3 mt-3" variant="secundary" @click="regresar">Regresar</b-btn>
            <b-btn class="float-right mr-3 ml-3 mt-3" variant="primary" @click="grabar">Grabar</b-btn>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import Autocomplete from "vuejs-auto-complete";
export default {
  components: {
    Autocomplete
  },
  data() {
    return {
      msg: "Nuevo proveedor: ",
      giros: [
        { value: "AAA", text: "AAA-Proveedores Internos" },
        { value: "PTA", text: "PTA-Puertas de Aluminio" },
        { value: "ACC", text: "ACC-Aires Acondicionados" },
        { value: "PLO", text: "PLO-Plomeria" },
        { value: "COR", text: "COR-Cortinas de Acero" },
        { value: "LOS", text: "LOS-Losetas y Pizos" },
        { value: "ELE", text: "ELE-Electricista" },
        { value: "PIN", text: "PIN-Pintura" },
        { value: "ROC", text: "ROC-Tablaroca" },
        { value: "PLA", text: "PLA-Plagas y Fumigaciones" },
        { value: "HER", text: "HER-Herreria" },
        { value: "MAN", text: "MAN-Mantenimiento" },
        { value: "PUB", text: "PUB-Publicidad" },
        { value: "FER", text: "FER-Ferreterias" },
        { value: "CER", text: "CER-Cerrajeria" },
        { value: "CON", text: "CON-Construccion" },
        { value: "PAU", text: "PAU-Puertas Automaticas" },
        { value: "ALS", text: "ALS-Alarma y Seguridad" },
        { value: "PER", text: "PER-Persianas" },
        { value: "MEC", text: "MEC-Servicio Automotriz" },
        { value: "REN", text: "REN-Renta de Bodega u Oficinas" },
        { value: "MUL", text: "MUL-Multiservicios" },
        { value: "TIE", text: "TIE-Tiendas" },
        { value: "CAR", text: "CAR-Carpinteria" },
        { value: "TEC", text: "TEC-Servicio Tecnico" },
        { value: "PAQ", text: "PAQ-Paqueteria" },
        { value: "HOT", text: "HOT-Hotel" }
      ].sort(function(a, b) {
        var x = a.value.toLowerCase();
        var y = b.value.toLowerCase();
        if (x < y) {
          return -1;
        }
        if (x > y) {
          return 1;
        }
        return 0;
      }),
      model: this.$route.params.model
        ? this.$route.params.model
        : {
            codigo: null,
            nombrecomercial: null,
            contacto: null,
            telefono: null,
            email: null,
            banco: null,
            cuenta: null,
            tarjeta: null,
            clabe: null,
            titular: null,
            ciudad: null,
            ciudad2: null,
            estado2: null
          },
      ciudad: JSON.stringify(this.$route.params.model) !== "null" ? this.$route.params.model.ciudadd.nombre + ", " + this.$route.params.model.ciudadd.estado : "",
      initialDisplay: "", //this.$route.params.model.ciudadd.nombre + ", " + this.$route.params.model.ciudadd.estado,
      initialValue: ""
    };
  },
  computed: {
    authHeaders() {
      return {
        Authorization: "Bearer " + this.$cookies.get("token")
      };
    },
    source() {
      return process.env.VUE_APP_ROOT_API + "api/secure/ciudades/";
    }
  },
  methods: {
    formattedDisplay(result) {
      return result.nombre + ", " + result.estado;
    },
    setDatosCiudad(item) {
      //console.log(JSON.stringify(item));
      this.model.ciudad = item.value;
      this.model.ciudadd = item.selectedObject;
      this.model.ciudad2 = item.selectedObject.nombre;

      this.ciudad =
        item.selectedObject.nombre + ", " + item.selectedObject.estado;
      this.$refs.autocomplete.clear();
    },
    //clear5() {},
    //provSearched5() {},
    regresar() {
      this.$router.replace({ path: "/portal/proveedores" });
    },
    grabar() {
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.$cookies.get("token");
      axios
        .post(
          process.env.VUE_APP_ROOT_API + "api/secure/proveedores",
          this.model
        )
        .then(
          response => {

            if( JSON.stringify(response.data) !== '""' ){
              this.$router.replace({ path: "/portal/proveedores" });
            }else{
              alert("NO SE GRABO, TODOS LOS CAMPOS SON REQUERIDOS.");
            }
          },
          error => {
            console.log("Error: " + JSON.stringify(error.data));
          }
        );
    }
  }
};
</script>

<style>
.xform-controlx {
  height: calc(2.25rem + 2px);
}
span.autocomplete__icon img {
  margin-bottom: 16px;
}
</style>